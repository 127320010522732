<template>
    <el-dialog :visible.sync="visible"
               :title="title"
               :close-on-click-modal="false"
               width="80%"
               custom-class="p-0"
               top="2vh">
        <div class="row">
            <div class="col">
                <fg-input class="no-border"
                          type="text"
                          label="Area Name"
                          v-model="value.name">
                </fg-input>
            </div>
            <div class="col-1">
                <fg-input class="no-border"
                          type="text"
                          label="Tag"
                          v-model="value.tag">
                </fg-input>
            </div>
            <div class="col">
                <fg-input class="no-border"
                          type="text"
                          label="Slug"
                          v-model="value.slug">
                </fg-input>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label class="d-block">
                    Require Bidders to Enter Bids
                    <el-tooltip class="area"
                                effect="dark"
                                content="When enabled controllers cannot enter their own bids">
                        <i class="fas fa-info-circle ml-2"></i>
                    </el-tooltip>
                </label>

                <n-switch v-model="value.use_bid_aid"
                          on-text="ON"
                          :on-value="1"
                          :off-value="0"
                          color="bg-lightblue"
                          class="align-top ml-2"></n-switch>
            </div>
            <div class="col">
                <label class="d-block">
                    Subtract Holiday Leave
                    <el-tooltip class="area"
                                effect="dark"
                                content="Holidays bid will be subtracted from the controller's leave balance.
                                Holidays are ALWAYS counted as a slot bid.">
                        <i class="fas fa-info-circle ml-2"></i>
                    </el-tooltip>
                </label>

                <n-switch v-model="value.subtract_holiday_leave"
                          on-text="ON"
                          :on-value="1"
                          :off-value="0"
                          color="bg-lightblue"
                          class="align-top ml-2"></n-switch>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <fg-input class="no-border"
                          type="text"
                          label="Slack Webhook"
                          v-model="value.slack_webhook">
                </fg-input>
            </div>
            <div class="col-3">
                <fg-input class="no-border"
                          type="text"
                          label="Slack Channel"
                          v-model="value.slack_channel">
                </fg-input>
            </div>
        </div>
      <div class="row">
        <div class="col">
          <label class="d-block">
            Discord Channel ID
            <el-tooltip class="area"
                        effect="dark"
                        content="Send notifications to a Discord channel.">
              <i class="fas fa-info-circle ml-2"></i>
            </el-tooltip>
            <br><i>More info <a
              href='https://support.natca.org/en/articles/9888983-bid-notifications-to-communications-platforms'>here.</a></i>
          </label>

          <fg-input class="no-border"
                    type="text"
                    v-model="value.discord_channel_id">
          </fg-input>
        </div>
      </div>
        <div class="row" v-if="isSuper">
            <div class="col">
                <label>Grace Hours For Bidding Leave Slot</label>
                <el-tooltip class="area" effect="dark" content="Extra hours available for controllers to bid leave slot. Accounts for situations where a controller only needs X hours to bid an additional slot.">
                    <i class="fas fa-info-circle ml-2"></i>
                </el-tooltip>
                <fg-input class="no-border"
                          type="text"
                          :maxlength="1"
                          v-model="value.grace_hours">
                </fg-input>
            </div>
            <div class="col">
                <label>Accrual Slot Calculation Factor</label>
                <el-tooltip class="area" effect="dark" content="Used to convert Accrual Hours for an area into Accrual Slots Available. Default is an 8-hour shift">
                    <i class="fas fa-info-circle ml-2"></i>
                </el-tooltip>
                <fg-input class="no-border"
                          type="text"
                          :maxlength="1"
                          v-model="value.accrual_slot_factor">
                </fg-input>
            </div>
        </div>
        <div slot="footer">
            <n-button type="default" round class="mr-1" @click.native="visible = false">Cancel</n-button>
            <n-button type="success" round @click.native="saveArea()">Submit</n-button>
        </div>
    </el-dialog>
</template>
<script>
import { Switch } from '@/components/stateless'
import { Dialog } from 'element-ui'
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
const AreaRepository = RepositoryFactory.get('area')

export default {
  // name: 'area-form',
  props: {
    value: Object,
    creating: Boolean,
    show: Boolean,
    isSuper: Boolean
  },
  components: {
    [Switch.name]: Switch,
    [Dialog.name]: Dialog
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    saveArea () {
      if (this.creating) {
        AreaRepository.create(this.value)
          .then((response) => {
            this.visible = false
            this.$emit('saved', response.data)
          })
      } else {
        AreaRepository.update(this.value.id, this.value)
          .then((response) => {
            this.visible = false
            this.$emit('saved', response.data)
          })
      }
    }
  },
  computed: {
    title () {
      return this.editing ? 'Edit '.this.value.name : 'Create New Area'
    }
  },
  created () {},
  watch: {
    visible (n) {
      this.$emit('update:show', n)
    },
    show (n) {
      this.visible = n
    },
    value (n) {
      this.$emit('input', n)
    }
  }
}
</script>
<style></style>
